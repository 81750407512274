import dayjs from "dayjs";

export function getMonth(month = dayjs().month()) {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}

export function getDia(dia) {
  dia = dia.trim();
  let diaDesc = "";
  switch (dia) {
    case "SUN":
      diaDesc = "DOM";
      break;
    case "MON":
      diaDesc = "SEG";
      break;
    case "TUE":
      diaDesc = "TER";
      break;
    case "WED":
      diaDesc = "QUA";
      break;
    case "THU":
      diaDesc = "QUI";
      break;
    case "FRI":
      diaDesc = "SEX";
      break;
    case "SAT":
      diaDesc = "SAB";
      break;

    default:
      break;
  }
  return diaDesc;
}
export function getMes(mes, minusculo = false) {
  mes = parseInt(mes);
  let mesDesc = "";
  switch (mes) {
    case 1:
      mesDesc = "JANEIRO";
      break;
    case 2:
      mesDesc = "FEVEREIRO";
      break;
    case 3:
      mesDesc = "MARÇO";
      break;
    case 4:
      mesDesc = "ABRIL";
      break;
    case 5:
      mesDesc = "MAIO";
      break;
    case 6:
      mesDesc = "JUNHO";
      break;
    case 7:
      mesDesc = "JULHO";
      break;
    case 7:
      mesDesc = "JULHO";
      break;
    case 8:
      mesDesc = "AGOSTO";
      break;
    case 9:
      mesDesc = "SETEMBRO";
      break;
    case 10:
      mesDesc = "OUTUBRO";
      break;
    case 11:
      mesDesc = "NOVEMBRO";
      break;
    case 12:
      mesDesc = "DEZEMBRO";
      break;

    default:
      break;
  }

  if (minusculo) {
    mesDesc = mesDesc.toLowerCase();
    mesDesc = mesDesc[0].toUpperCase() + mesDesc.substring(1);
  }

  return mesDesc;
}
export function getDiaSemana(dia) {
  dia = parseInt(dia);
  let diaDesc = "";
  switch (dia) {
    case 0:
      diaDesc = "Domingo";
      break;
    case 1:
      diaDesc = "Segunda-Feira";
      break;
    case 2:
      diaDesc = "Terça-Feira";
      break;
    case 3:
      diaDesc = "Quarta-Feira";
      break;
    case 4:
      diaDesc = "Quinta-Feira";
      break;
    case 5:
      diaDesc = "Sexta-Feira";
      break;
    case 6:
      diaDesc = "Sábado";
      break;

    default:
      break;
  }
  return diaDesc;
}
